<template>
    <div
        v-if="metadata"
        class="subscription-editor-base"
    >
        <y-panel :title="$t('subscription.editor_base')">
            <y-row>
                <y-col sm="4">
                    <y-form-field
                        v-model="model.title"
                        type="text"
                        name="title"
                        validation="required"
                    />
                </y-col>

                <y-col sm="4">
                    <y-form-field
                        v-model="model.slug"
                        type="text"
                        name="slug"
                        validation="required"
                    />
                </y-col>

                <y-col sm="4">
                    <y-form-field
                        v-model="model.category_id"
                        type="select"
                        :options="metadata.categories"
                        :label="$t('fields.category')"
                        validation="required"
                        allow-empty
                    />
                </y-col>
            </y-row>
            
            <y-row>
                <y-col sm="4">
                    <y-form-field
                        v-model="model.volume_time_value"
                        type="number"
                        :label="$t('subscription.volume_time_value')"
                    />
                </y-col>

                <y-col sm="4">
                    <y-form-field
                        v-model="model.volume_time_type"
                        type="select"
                        :options="metadata.time_types"
                        :disabled="!model.volume_time_value"
                        :label="$t('subscription.volume_time_type')"
                    />
                </y-col>

                <y-col sm="4">
                    <y-form-field
                        v-model="model.volume_const_value"
                        type="number"
                        :label="$t('subscription.volume_const_value')"
                    />
                </y-col>
            </y-row>

            <y-row>
                <y-col sm="6">
                    <y-form-uploader
                        ref="uploader"
                        v-model="model.image"
                        :payload="metadata.payload"
                        name="package_image"
                        :max-files="1"
                    />
                </y-col>

                <y-col sm="6">
                    <y-form-editor
                        v-model="model.description"
                        name="description"
                        toolbar="mini"
                        :height="330"
                    />
                </y-col>

                <y-col
                    sm="12"
                    class="mt20"
                >
                    <y-form-cloner
                        v-model="model.price_array"
                        :max="metadata.currencies.length"
                        :children="formFields"
                        no-label
                    />
                </y-col>

                <y-col sm="12">
                    <y-form-divider dotted />

                    <y-form-field
                        v-model="useAdvancedSettings"
                        type="checkbox"
                        :label="$t('subscription.use_advanced_settings')"
                        @input="$emit('hasSelfSetting', $event)"
                    />
                </y-col>
            </y-row>
        </y-panel>
    </div>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';

    import {
        YFormField,
        YFormUploader,
        YFormCloner,
        YFormEditor,
    } from '@deps';

    import YFormDivider from '@deps/form/elements/Divider';

    export default {
        name: 'SubscriptionBasePanel',

        components: {
            YFormField,
            YFormUploader,
            YFormCloner,
            YFormDivider,
            YFormEditor,
        },

        props: {
            metadata: Object,
            value   : Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: this.value || {
                    price_array: [{
                        currency      : this.metadata.currency_default ?? null,
                        original_price: '',
                        sale_price    : '',
                    }],
                },

                useAdvancedSettings: !!(this.value?.has_self_setting * 1) || null,
            };
        },

        computed: {
            /**
             * Return Array of currency Options
             */
            currencyOptions() {
                return this.metadata && this.metadata.currencies ? this.metadata.currencies.map((currency) => ({
                    label: currency.label,
                    value: currency.value,
                })) : [];
            },

            /**
             * Return Array of form elements required to render cloner rows
             */
            formFields() {
                return [
                    {
                        component_name: 'select',
                        dir           : 'auto',
                        name          : 'currency',
                        label         : this.$t('subscription.currency'),
                        placeholder   : this.$t('subscription.currency'),
                        id            : `select-${generateId()}`,
                        options       : this.currencyOptions,
                        class         : 'col-12 col-sm-4',
                        validation    : 'required',
                    },
                    {
                        component_name: 'number',
                        dir           : 'auto',
                        name          : 'original_price',
                        format        : 'separated',
                        label         : this.$t('subscription.original_price'),
                        id            : `number-${generateId()}`,
                        class         : 'col-12 col-sm-4',
                        validation    : 'required',
                    },
                    {
                        component_name: 'number',
                        dir           : 'auto',
                        name          : 'sale_price',
                        format        : 'separated',
                        label         : this.$t('subscription.sale_price'),
                        id            : `number-${generateId()}`,
                        class         : 'col-12 col-sm-4',
                        validation    : '',
                    },
                ];
            },
        },

        watch: {
            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            if (!this.model.title) {
                this.model.title = '';
            }
        },
    };

</script>
