<template>
    <div>
        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-general-settings-form')"
            height="calc( 100vh - 239px )"
        >
            <main>
                <form>
                    <y-row v-if="panels && panels.length">
                        <y-col
                            v-for="(col, i) in panels"
                            :key="`form-col-${i}`"
                            sm="6"
                            class="subscription-settings-panel-actions"
                        >
                            <y-panel
                                v-for="(panel, j) in col"
                                :key="`form-panel-${j}`"
                                :title="panel.title"
                            >
                                <template slot="functions">
                                    <y-form :params="panel.buttons" />
                                </template>
                                <y-form
                                    v-model="model"
                                    :params="panel.form"
                                />
                            </y-panel>
                        </y-col>
                    </y-row>
                </form>
            </main>
        </y-loading>

        <y-seller-modal
            v-if="!$wait.is('loading-general-settings-form')"
            ref="SellerModal"
        />
    </div>
</template>

<script>
    // Mixins
    import FormMixin from '@/mixins/Form';
    import GlobalMixin from '@/mixins/Global';

    // Components
    import { YForm } from '@deps';
    import YSellerModal from '../SellerModal';

    export default {

        name: 'GeneralSettingsForm',

        components: {
            YForm,
            YSellerModal,
        },

        mixins: [FormMixin, GlobalMixin],

        props: {
            generalSettings: Object,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: null,

                panels: [
                    {
                        title     : this.$t('subscription.wallet_settings.title'),
                        active_key: 'wallet_active',
                        active    : true,
                        form      : [
                            {
                                component_name: 'checkbox',
                                id            : 'checkbox-hzr6fSOHPE',
                                name          : 'wallet_active',
                                label         : this.$t('subscription.wallet_settings.label'),
                            },
                        ],
                    },
                    {
                        title     : this.$t('subscription.payment_settings.title'),
                        active_key: 'payment_active',
                        active    : true,
                        form      : [
                            {
                                component_name: 'select',
                                id            : 'select-HmrsC2dgkt',
                                name          : 'payment_online_gate',
                                placeholder   : this.$t('subscription.payment_settings.unavailable'),
                                label         : this.$t('subscription.payment_settings.online_path'),
                                options       : [],
                                multi         : true,
                                allow_empty   : true,
                                label_field   : 'title',
                                value_field   : 'id',
                            },
                            {
                                component_name: 'select',
                                id            : 'select-RqTVsNjboM',
                                name          : 'payment_offline_gate',
                                placeholder   : this.$t('subscription.payment_settings.unavailable'),
                                label         : this.$t('subscription.payment_settings.offline_path'),
                                options       : [],
                                multi         : true,
                                allow_empty   : true,
                                label_field   : 'title',
                                value_field   : 'id',
                            },
                        ],
                    },
                    {
                        title     : this.$t('subscription.invoice_settings.title'),
                        active_key: 'invoice_active',
                        active    : true,
                        form      : [
                            {
                                component_name: 'checkbox',
                                id            : 'checkbox-r6sdffSOHPE',
                                name          : 'invoice_generate',
                                label         : this.$t('subscription.invoice_settings.invoice_generate'),
                            },
                            {
                                component_name: 'text',
                                id            : 'text-Fi4hiZt474',
                                name          : 'invoice_prefix',
                                label         : this.$t('subscription.invoice_settings.invoice_prefix'),
                                help          : this.$t('subscription.invoice_settings.invoice_prefix_help'),
                            },
                            {
                                component_name: 'select',
                                id            : 'select-sC2ddfggkt',
                                name          : 'invoice_required_field',
                                placeholder   : '',
                                label         : this.$t('subscription.invoice_settings.invoice_required_field'),
                                options       : [],
                                multi         : true,
                                allow_empty   : true,
                            },
                        ],
                        buttons: [{
                            component_name: 'subscription-modal-button',
                            id            : 'link-XjLRz5ATvC',
                            label         : this.$t('subscription.invoice_settings.seller_info'),
                            handleClick   : () => {
                                this.$refs.SellerModal.openModal({ id: 'SellerInfoModal' });
                            },
                        }],
                    },
                    {
                        title     : this.$t('subscription.notifier_settings.title'),
                        active_key: 'notifier_active',
                        active    : true,
                        form      : [
                            {
                                component_name: 'checkbox',
                                id            : 'checkbox-dfFfSUYFA',
                                name          : 'notif_activate',
                                label         : this.$t('subscription.notifier_settings.notif_activate'),
                            },
                            {
                                component_name: 'checkbox',
                                id            : 'checkbox-80fdglgsdg',
                                name          : 'notif_one_month',
                                label         : this.$t('subscription.notifier_settings.notif_one_month'),
                            },
                            {
                                component_name: 'checkbox',
                                id            : 'checkbox-4sdfs5zgsfg',
                                name          : 'notif_one_week',
                                label         : this.$t('subscription.notifier_settings.notif_one_week'),
                            },
                            {
                                component_name: 'checkbox',
                                id            : 'checkbox-mseouts',
                                name          : 'notif_one_day',
                                label         : this.$t('subscription.notifier_settings.notif_one_day'),
                            },
                            {
                                component_name: 'checkbox',
                                id            : 'checkbox-dfdsSUYFA',
                                name          : 'notif_fifty_percent',
                                label         : this.$t('subscription.notifier_settings.notif_fifty_percent'),
                            },
                            {
                                component_name: 'checkbox',
                                id            : 'checkbox-kpiiytry',
                                name          : 'notif_ninety_percent',
                                label         : this.$t('subscription.notifier_settings.notif_ninety_percent'),
                            },
                            {
                                component_name: 'checkbox',
                                id            : 'checkbox-dfFfasdflYFA',
                                name          : 'notif_finished',
                                label         : this.$t('subscription.notifier_settings.notif_finished'),
                            },
                        ],
                    },
                    {
                        title     : this.$t('subscription.discount_settings.title'),
                        active_key: '',
                        active    : true,
                        form      : [
                            {
                                component_name: 'checkbox',
                                id            : 'checkbox-a1JBoxeObu',
                                name          : 'discount_available',
                                label         : this.$t('subscription.discount_settings.discount_available'),
                            },
                        ],
                    },
                ],
            };
        },

        watch: {
            model: {
                /**
                 * Watch value emit model
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritdoc
         */
        created() {
            this.fetch();
        },

        methods: {
            /**
             * Fetch Subscription general settings
             */
            fetch() {
                this.$wait.start('loading-general-settings-form');
                return this.$services.Subscription.generalSettingsFetch().then((response) => {
                    if (this.generalSettings) {
                        this.handleResponse(this.generalSettings, response.data.metadata);
                    } else {
                        this.handleResponse(response.data.results, response.data.metadata);
                    }
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('loading-general-settings-form');
                });
            },

            /**
             * handle response general settings
             *
             * @param {*} results
             * @param {*} metadata
             */
            handleResponse(results, metadata) {
                this.panels = this.chunk(
                    this.panels.map((item) => ({
                        ...item,
                        active: item.active_key ? metadata[item.active_key] : true,
                        form  : item.form.map((element) => {
                            if (element.options) {
                                return { ...element, options: metadata[element.name] || element.options };
                            }
                            return element;
                        }),
                    })).filter((element) => element.active),
                );

                this.registerGlobalModals({
                    SellerInfoModal: {
                        size   : 'md',
                        id     : 'SellerInfoModal',
                        title  : this.$t('subscription.seller_info_modal.title'),
                        buttons: [
                            {
                                component_name: 'button',
                                id            : 'Subscription-SellerInfoModal-mainButton',
                                title         : this.$t('button.submit'),
                                color         : 'green',
                                tag           : 'button',
                                action        : {
                                    type   : 'callSubmit',
                                    options: {
                                        name   : 'subscription-seller-info-save',
                                        method : 'post',
                                        version: 1,
                                        success: [
                                            {
                                                type: 'closeModal',
                                            },
                                            {
                                                type: 'refresh',
                                                area: 'row',
                                            },
                                            {
                                                type   : 'toast',
                                                message: this.$t('subscription.seller_info_modal.done'),
                                            },
                                        ],
                                        error: [
                                            {
                                                type   : 'toast',
                                                message: this.$t('subscription.seller_info_modal.error'),
                                            },
                                        ],
                                    },
                                },
                            },
                            {
                                component_name: 'button',
                                id            : 'Subscription-SellerInfoModal-cancelButton',
                                title         : this.$t('button.cancel'),
                                color         : null,
                                tag           : 'button',
                                action        : {
                                    type: 'closeModal',
                                },
                            },
                        ],
                        fetch: {
                            name   : 'subscription-seller-info-fetch',
                            method : 'get',
                            version: 1,
                            mock   : false,
                            params : [],
                        },
                    },
                });

                this.model = results;
            },

            /**
             * Chunk array
             *
             * @param {Array} arr
             * @returns {Array}
             */
            chunk(arr) {
                const chunks = [arr];
                chunks[1] = chunks[0].splice(0, Math.floor(arr.length / 2));
                return chunks;
            },
        },

    };
</script>
