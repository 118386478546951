<template>
    <div>
        <y-page-head
            :title="title"
            icon="md-package"
            :class="[{disabled: $wait.is('loading-page')}]"
        >
            <template slot="actions">
                <y-button
                    v-if="isEdit"
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <!-- Save -->
                <y-button
                    color="blue"
                    form="main-form"
                    wide
                    loading-on="submitting-form"
                >
                    {{ $t('button.save') }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main>
                <form
                    id="main-form"
                    @submit.prevent="submit(save)"
                >
                    <y-base-panel
                        v-model="model"
                        :metadata="metadata"
                        @hasSelfSetting="hasSelfSetting = $event"
                    />

                    <y-panel
                        v-if="hasSelfSetting"
                        :title="$t('subscription.advanced_settings')"
                    >
                        <y-general-settings-form
                            v-model="generalSettingsModel"
                            :general-settings="settings"
                        />
                    </y-panel>
                </form>
            </main>
        </y-loading>
    </div>
</template>

<script>

    // Mixins
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    // Components
    import YBasePanel from '@/modules/subscription/components/BasePanel';
    import { YGeneralSettingsForm } from '@modules/subscription/components/GeneralSettingsForm';

    export default {
        name: 'SubscriptionEdit',

        components: {
            YBasePanel,
            YGeneralSettingsForm,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.title,
            };
        },

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('subscription', this.$t('breadcrumbs.subscription')),
                this.$bc(
                    (this.$route.params.id && !this.$route.query.duplicate)
                        ? this.$t('subscription.title.edit')
                        : this.$t('subscription.title.create'),
                ),
            ];
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model               : null,
                generalSettingsModel: null,
                settings            : null,
                metadata            : null,
                hasSelfSetting      : false,
                selectedCategory    : {},
            };
        },

        computed: {
            /**
             * Title of the page
             */
            title() {
                return this.isEdit ? this.$t('subscription.title.edit') : this.$t('subscription.title.create');
            },

            /**
             * indicate whether its in clone mode
             */
            isClone() {
                return !!this.$route.query.duplicate;
            },

            /**
             * Check if it is in edit mode
             */
            isEdit() {
                return !!this.$route.params.id && !this.isClone;
            },
        },

        methods: {
            /**
             * Fetch package settings
             */
            fetch() {
                const params = {
                    id       : this.$route.params.id,
                    duplicate: this.isClone ? 1 : 0,
                    including: ['title', 'slug', 'price_array', 'settings',
                                'category_id', 'description', 'image',
                                'volume_const_value', 'volume_time_type',
                                'volume_time_value', 'has_self_setting'],
                };
                
                return this.$services.Subscription.packageFetchPanel(params).then((response) => {
                    this.metadata = response.data.metadata;

                    this.$set(this, 'settings', response.data.results.settings);
                    this.model = response.data.results;
                    this.hasSelfSetting = !!(response.data.results.has_self_setting * 1);

                    if (this.isClone) {
                        this.model.title = '';
                    }
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Save package
             */
            save() {
                if (this.isClone) {
                    delete this.model.id;
                }

                if (this.model) {
                    if (this.model.volume_time_value) {
                        this.model.volume_type = 'time';
                    } else if (this.model.volume_const_value) {
                        this.model.volume_type = 'const';
                    } else if (this.model.volume_time_value && this.model.volume_const_value) {
                        this.model.volume_type = 'combined';
                    }
                }

                let params = { ...this.model };

                if (this.model?.image) {
                    params.image = this.model.image.join('');
                }

                if (this.hasSelfSetting && this.generalSettingsModel) {
                    params.has_self_setting = true;
                    params = { ...params, ...this.generalSettingsModel };

                    if (params.invoice_seller_information) {
                        delete params.invoice_seller_information;
                    }
                } else {
                    params.has_self_setting = false;
                }

                if (params.settings) {
                    delete params.settings;
                }

                return this.$services.Subscription.packageSave(params).then(() => {
                    this.$toast.success(this.$t('subscription.editor.messages.success'));
                    this.$router.push({
                        name  : 'panel-grid',
                        params: {
                            class  : 'packages',
                            module : 'Subscription',
                            part   : 'list',
                            version: '1',
                        },
                    });
                }).catch((error) => {
                    this.handleError(error);
                });
            },
        },
    };
</script>
