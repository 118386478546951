<template>
    <div>
        <y-page-head
            :title="pageTitle"
            icon="md-settings"
            :class="[{disabled: $wait.is('loading-page')}]"
        >
            <template slot="actions">
                <!-- Refresh -->
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <!-- Save -->
                <y-button
                    color="blue"
                    wide
                    loading-on="submitting-form"
                    @click.prevent="submit"
                >
                    {{ $t('button.save') }}
                </y-button>
            </template>
        </y-page-head>

        <y-general-settings-form
            ref="GeneralSettingsForm"
            v-model="model"
        />
    </div>
</template>

<script>

    // Mixins
    import PageMixin from '@/mixins/Page';

    // Components
    import { YGeneralSettingsForm } from '@modules/subscription/components/GeneralSettingsForm';

    export default {
        name: 'SubscriptionGeneralSettings',

        components: {
            YGeneralSettingsForm,
        },

        mixins: [PageMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('subscription', this.$t('breadcrumbs.subscription')),
                this.$bc(this.$t('subscription.title.general_settings')),
            ];
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                pageTitle: this.$t('subscription.title.general_settings'),

                model: null,
            };
        },

        methods: {
            /**
             * Save Subscription general settings
             */
            submit() {
                this.$wait.start('submitting-form');
                const params = this.model;
                if (params.invoice_seller_information) {
                    delete params.invoice_seller_information;
                }

                return this.$services.Subscription.generalSettingsSave(params).then(() => {
                    this.$toast.success(this.$t('messages.save.success', { type: this.$t('subscription.title.general_settings') }));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('submitting-form');
                });
            },

            /**
             * Fetching data
             */
            reFetch() {
                this.$refs.GeneralSettingsForm.fetch();
            },
        },
    };
</script>
