<template>
    <div class="grid-modals">
        <template v-if="list && Object.keys(list).length">
            <y-modal
                v-for="(modal, id) in list"
                :key="modalRef(id)"
                :ref="modalRef(id)"
                :title="modal.title"
                :size="modal.size"
                @close="closeModal('direct')"
                @open="fetchForm(modal)"
            >
                <!-- Modal Body -->
                <template slot="body">
                    <form id="panel-grid-model-form">
                        <y-form
                            v-if="modal.body && modal.body.length"
                            :key="`grid-modal-body-${modal.id}-${keyHelper}`"
                            v-model="model"
                            :params="modal.body"
                        />

                        <y-loading
                            v-if="modal.fetch"
                            height="100px"
                            :active="!form"
                        >
                            <y-form
                                v-model="model"
                                :params="form"
                            />
                        </y-loading>
                    </form>
                </template>

                <!-- Modal Footer -->
                <template
                    v-if="modal.buttons && modal.buttons.length"
                    slot="footer"
                >
                    <y-button
                        v-for="button in modal.buttons"
                        :key="`grid-modal-button-${button.id}-${keyHelper}`"
                        :color="button.color"
                        :loading-on="`button-loading-${button.id}`"
                        @click.prevent="handleAction(button)"
                    >
                        {{ button.title }}
                    </y-button>
                </template>
            </y-modal>
        </template>
    </div>
</template>

<script>

    import { generateId } from '@nodes/helpers/string';

    import FormMixin from '@/mixins/Form';

    import { YModal, YForm } from '@deps';

    export default {

        name: 'SellerModal',

        components: {
            YModal,
            YForm,
        },

        mixins: [FormMixin],

        inject: {
            $validator: '$validator',
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                form        : null,
                model       : null,
                params      : null,
                calledButton: null,
                list        : this.$store.getters['modals/list'],

                keyHelper: generateId(),
            };
        },

        methods: {

            /**
             * Create modal ref
             *
             * @param id
             */
            modalRef(id) {
                return `grid-modal-${id}`;
            },

            /**
             * Open grid modal
             *
             * @param data
             */
            openModal(data) {
                this.$set(this, 'params', data.params);
                this.$set(this, 'model', data.params);
                this.$refs[this.modalRef(data.id)][0].open();
            },

            /**
             * Handle close modal event
             *
             * @param type
             */
            closeModal(type = null) {
                if (type !== 'direct') {
                    if (this.list) {
                        this.$refs[this.modalRef(Object.keys(this.list)[0])][0].close();
                    }
                }
                this.$set(this, 'form', null);
                this.$set(this, 'model', null);
                this.$set(this, 'params', null);
            },

            /**
             * Handle action of button
             *
             * @param button
             */
            handleAction(button) {
                const { type } = button.action;
                if (type === 'callEndpoint' || type === 'callSubmit') {
                    this.submit(() => this.callEndpointAction(button));
                } else if (type === 'closeModal') {
                    this.closeModal();
                } else {
                    this.$root.$emit(type);
                }
            },

            /**
             * Call endpoint action
             *
             * @param button
             */
            callEndpointAction(button) {
                let { params } = button.action.options;
                const {
                    name, method, version, mock, success, error,
                } = button.action.options;

                if (button.action.type === 'callSubmit') {
                    params = { ...this.model, ...this.params };
                }

                this.$wait.start(`button-loading-${button.id}`);

                return this.$services[method](name, [params, version, mock]).then((response) => {
                    const { redirect } = response.data.metadata;
                    if (redirect) {
                        this.handleLocationRedirect(redirect);
                    }
                    if (success && success.length) {
                        success.forEach((action) => {
                            this.handleResponseAction({
                                action,
                                response,
                                type: 'success',
                                button,
                                params,
                            });
                        });
                    }
                }).catch((response) => {
                    if (error && error.length) {
                        error.forEach((action) => {
                            this.handleResponseAction({
                                action,
                                response,
                                type: 'error',
                                button,
                                params,
                            });
                        });
                    }
                }).finally(() => {
                    this.$wait.end(`button-loading-${button.id}`);
                });
            },

            /**
             * Fetch modal form if exists
             *
             * @param modal
             */
            fetchForm(modal) {
                if (modal.fetch) {
                    const {
                        name, version, mock, success, error,
                    } = modal.fetch;

                    const params = { ...modal.fetch.params, ...this.params };

                    this.$wait.start(`form-loading-${modal.id}`);

                    this.$services.get(name, [params, version, mock]).then((response) => {
                        this.form = [{
                            component_name: 'conditional',
                            order         : 1,
                            children      : {
                                real: [
                                    {
                                        component_name: 'text',
                                        id            : 'text-tGaYtmBlA2',
                                        name          : 'name',
                                        label         : this.$t('fields.name'),
                                    },
                                    {
                                        component_name: 'number',
                                        id            : 'number-d2DWzXnlJI',
                                        name          : 'tel',
                                        label         : this.$t('fields.tel'),
                                        format        : '(DDD) DDDD DDDD',
                                    },
                                    {
                                        component_name: 'number',
                                        id            : 'number-IrF7Vnt46n',
                                        name          : 'code_melli',
                                        label         : this.$t('fields.code_melli'),
                                        format        : 'DDD-DDD-DDD-D',
                                    },
                                    {
                                        component_name: 'number',
                                        id            : 'number-tmtuYgK1Pg',
                                        name          : 'postal_code',
                                        label         : this.$t('fields.postal_code'),
                                        format        : 'DDDDD-DDDDD',
                                    },
                                    {
                                        component_name: 'textarea',
                                        id            : 'textarea-IkYLPrcwJO',
                                        name          : 'address',
                                        label         : this.$t('fields.address'),
                                        no_resize     : true,
                                    },
                                    {
                                        component_name: 'division',
                                        id            : 'division-y8ZQgMR2Y5',
                                        name          : 'division',
                                        limit         : 'city',
                                        hide_empties  : true,
                                    },
                                ],
                                legal: [
                                    {
                                        component_name: 'text',
                                        id            : 'text-YwJbUZTv65',
                                        name          : 'name',
                                        label         : this.$t('fields.name'),
                                    },
                                    {
                                        component_name: 'number',
                                        id            : 'number-KQm9VSLyVs',
                                        name          : 'eco_no',
                                        label         : this.$t('fields.eco_no'),
                                        format        : 'DDDD-DDDD-DDDD',
                                    },
                                    {
                                        component_name: 'number',
                                        id            : 'number-4Kp7XkGl0Y',
                                        name          : 'code_melli',
                                        label         : this.$t('fields.nationality_code'),
                                        format        : 'DDDDDDDDDDD',
                                    },
                                    {
                                        component_name: 'number',
                                        id            : 'number-ykcdQtCOBl',
                                        name          : 'reg_no',
                                        label         : this.$t('fields.reg_no'),
                                        dir           : 'auto',
                                        format        : 'DDDDDDDDDDD',
                                    },
                                    {
                                        component_name: 'number',
                                        id            : 'number-e4ttg1QENq',
                                        name          : 'tel',
                                        label         : this.$t('fields.tel'),
                                        format        : '(DDD) DDDD DDDD',
                                    },
                                    {
                                        component_name: 'number',
                                        id            : 'number-lKLBuZ4Qt8',
                                        name          : 'postal_code',
                                        label         : this.$t('fields.postal_code'),
                                        format        : 'DDDDD-DDDDD',
                                    },
                                    {
                                        component_name: 'textarea',
                                        id            : 'textarea-J5QF2yBQAn',
                                        name          : 'address',
                                        resources     : [],
                                        label         : this.$t('fields.address'),
                                    },
                                    {
                                        component_name: 'division',
                                        id            : 'division-Fz0zz9byKt',
                                        name          : 'legal_division',
                                        dir           : 'auto',
                                        limit         : 'city',
                                        hide_empties  : true,
                                    },
                                ],
                            },
                            id           : 'conditional-gQgEdEDiD2ni',
                            name         : 'seller_type',
                            keep_children: true,
                            type         : 'select',
                            label        : this.$t('subscription.seller_type.title'),
                            value        : 'real',
                            options      : [
                                {
                                    label: this.$t('subscription.seller_type.real'),
                                    value: 'real',
                                },
                                {
                                    label: this.$t('subscription.seller_type.legal'),
                                    value: 'legal',
                                },
                            ],
                            ignore_name: true,
                        }];
                        this.model = { ...response.data.results, ...this.model };
                        if (success && success.length) {
                            success.forEach((action) => {
                                this.handleResponseAction({
                                    action,
                                    response,
                                    type: 'success',
                                    modal,
                                });
                            });
                        }
                    }).catch((response) => {
                        if (error && error.length) {
                            error.forEach((action) => {
                                this.handleResponseAction({
                                    action,
                                    response,
                                    type: 'error',
                                    modal,
                                });
                            });
                        }
                    }).finally(() => {
                        this.$wait.end(`form-loading-${modal.id}`);
                    });
                } else {
                    this.keyHelper = generateId();
                }
            },

            /**
             * Handle actions of response
             *
             * @param args
             */
            handleResponseAction(args) {
                const { params } = args;
                const { type, area } = args.action;
                if (type === 'closeModal') {
                    this.closeModal();
                } else if (type === 'toast') {
                    return args.type === 'success'
                        ? this.$toast.success(args.action.message)
                        : this.handleError(args.response, args.action.message);
                } else if (type === 'refresh') {
                    if (Array.isArray(area)) {
                        area.forEach((item) => this.handleRefreshAction(item, params));
                    } else {
                        this.handleRefreshAction(area, params);
                    }
                } else if (type === 'redirect') {
                    this.$router.push(args.action.link);
                }

                return true;
            },

            /**
             * Handle refresh action
             *
             * @param area
             * @param params
             */
            handleRefreshAction(area, params) {
                const payload = { refresh: area };

                if (area === 'row') {
                    payload.refresh = params.id;
                }

                if (area === 'layout') {
                    return this.$root.$emit('refreshLayout');
                }

                return this.$root.$emit('refreshGrid', payload);
            },

            /**
             * Sets event listener on document to close dropdown
             * list when anywhere out of dropdown is clicked
             */
            setListenerForClosing() {
                const vm = this;
                document.addEventListener('click', () => {
                    vm.close();
                });
            },

            /**
             * Redirect to external locations
             *
             * @param {*} options
             */
            handleLocationRedirect(options) {
                const { target } = options;
                if (target === '_blank') {
                    // external links
                    const { url } = options;
                    const anchorLink = document.createElement('a');
                    anchorLink.href = url;
                    anchorLink.target = '_blank';
                    anchorLink.rel = 'noreferrer noopener';
                    anchorLink.click();
                } else {
                    // internal links
                    this.$router.push(options);
                }
            },

        },

    };
</script>
